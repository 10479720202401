import React, { useState, useEffect } from "react";
import FlashCard from "./FlashCard";
import { Button, Spinner } from "react-bootstrap";
import { GetNativeSentenceForFlashCard } from "../chat/OpenAiCommunication";
import { useNavigate } from "react-router-dom";
import { GetTranslationFeedback } from "./OpenAiTranslationFeedback";
import { FaTimes } from "react-icons/fa";

const getSentence = async (word) => {
  return await GetNativeSentenceForFlashCard(word.text);
};

const checkTranslation = async (word, nativeSentence, userTranslation) => {
  return await GetTranslationFeedback(
    word.text,
    nativeSentence,
    userTranslation,
  );
};

const Deck = ({ words = [], folder = { title: "Untitled" } }) => {
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [sessionEnded, setSessionEnded] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const navigate = useNavigate();

  // Update loading state when words change
  useEffect(() => {
    if (words && words.length >= 0) {
      setIsLoading(false);
    }
  }, [words]);

  const handleNextCard = () => {
    if (currentCardIndex < words.length - 1) {
      setCurrentCardIndex(currentCardIndex + 1);
    } else if (words.length > 0) {
      setSessionEnded(true);
    }
    // Do nothing if words.length === 0
  };

  const handleCloseSession = () => {
    navigate("/");
  };

  return (
    <div className="d-flex flex-column align-items-center w-100 my-1">
      {/* Title for the learning session */}
      <h2 className="text-center">Learning Session for "{folder.title}"</h2>

      {/* Close session (cross) button */}
      <Button
        variant="link"
        onClick={handleCloseSession}
        className="position-absolute"
        style={{
          top: "5px",
          right: "10px",
          fontSize: "1.2rem",
          color: "#dc3545",
          padding: "0",
          cursor: "pointer",
        }}
      >
        <FaTimes />
      </Button>

      {isLoading ? (
        <div className="text-center">
          <Spinner animation="border" />
          <p>Loading...</p>
        </div>
      ) : sessionEnded ? (
        <div className="text-center">
          <h2>Session Completed!</h2>
          <p>You've gone through all the cards.</p>
          <Button variant="primary" onClick={handleCloseSession}>
            Return to Home
          </Button>
        </div>
      ) : words && words.length > 0 && currentCardIndex < words.length ? (
        <FlashCard
          word={words[currentCardIndex]}
          getSentence={getSentence}
          checkTranslation={checkTranslation}
          onNextCard={handleNextCard}
        />
      ) : (
        <div className="text-center">
          <p>No words available for this session. Please add words to start.</p>
          <Button variant="primary" onClick={handleCloseSession}>
            Return to Home
          </Button>
        </div>
      )}
    </div>
  );
};

export default Deck;
