import { useState } from "react";
import { generateClient } from "aws-amplify/api";

import { createFolder } from "../graphql/mutations";

import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";

function HomePage({ myfolders }) {
  const [folders, setFolders] = useState([...myfolders]);
  const [folderData, setFolderData] = useState({ title: "" });

  const createNewFolder = async () => {
    try {
      const { title } = folderData;

      const newFolder = {
        title: title,
      };

      const client = generateClient();

      const createFolderResponse = await client.graphql({
        query: createFolder,
        variables: {
          input: newFolder,
        },
      });

      setFolders([...folders, createFolderResponse.data.createFolder]);
    } catch (err) {
      console.log("error", err);
    }
  };

  return (
    <Container>
      <Row className="px-4 my-4">
        <Col>
          <h1>Folders</h1>
        </Col>
      </Row>
      <Row xs={1} md={4} className="g-4">
        {folders.map((folder, indx) => {
          return (
            <Col key={indx}>
              <Card className="p-3">
                <Card.Body>
                  <Card.Title>{folder.title}</Card.Title>
                </Card.Body>
                <div className="d-grid gap-3">
                  <Button
                    href={"folders/" + folder.id}
                    variant="outline-primary"
                    size="sm"
                  >
                    Open
                  </Button>
                  <Button
                    href={"learningSession/" + folder.id}
                    variant="primary"
                    size="sm"
                  >
                    Learn Session
                  </Button>
                </div>
              </Card>
            </Col>
          );
        })}
      </Row>
      <Row className="px-4 my-5">
        <Col sm={3}>
          <h6>Create a New Folder</h6>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicText">
              <Form.Control
                type="text"
                placeholder="Folder title"
                value={folderData.title}
                onChange={(evt) =>
                  setFolderData({ ...folderData, title: evt.target.value })
                }
              ></Form.Control>
            </Form.Group>
            <Button variant="primary" type="button" onClick={createNewFolder}>
              Create Folder
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default HomePage;
