// App.js
import React from "react";
import logo from "./assets/images/logo.png";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@aws-amplify/ui-react/styles.css";
import "./assets/css/customAmplifyAuthStyles.css";

import { Amplify } from "aws-amplify";
import {
  Button,
  Heading,
  Image,
  Text,
  useAuthenticator,
  useTheme,
  View,
} from "@aws-amplify/ui-react";
import { Authenticator } from "@aws-amplify/ui-react";
import awsExports from "./aws-exports";

import { Spinner } from "react-bootstrap";
import AppContent from "./components/AppContent";

Amplify.configure(awsExports);

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image alt="Speak-n-bloom logo" src={logo} />
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>
          &copy; All Rights Reserved
        </Text>
      </View>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Sign in to your account
        </Heading>
      );
    },
    Footer() {
      const { toForgotPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toForgotPassword}
            size="small"
            variation="link"
          >
            Reset Password
          </Button>
        </View>
      );
    },
  },

  SignUp: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Create a new account
        </Heading>
      );
    },
    Footer() {
      const { toSignIn } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toSignIn}
            size="small"
            variation="link"
          >
            Back to Sign In
          </Button>
        </View>
      );
    },
  },
  // ... (Other Authenticator components like ConfirmSignUp, SetupTotp, etc.)
};

function App() {
  const signInRedirectFromWindow = String(
    window.location.origin + window.location.pathname,
  );
  const signInRedirectFromConfig = awsExports.oauth.redirectSignIn;

  console.log({ signInRedirectFromWindow, signInRedirectFromConfig });
  return (
    <Authenticator
      components={components}
      loginMechanism={["email"]}
      socialProviders={["google"]}
    >
      {({ signOut, user, route }) => {
        const isLoading = route === "loading";

        if (isLoading) {
          // Show a loading spinner or placeholder content
          return (
            <div className="text-center mt-5">
              <Spinner animation="border" variant="primary" />
            </div>
          );
        }

        return <AppContent signOut={signOut} user={user} />;
      }}
    </Authenticator>
  );
}

export default App;
