import React, { useState } from "react";
import { Navbar, Nav, Dropdown, Container, Row, Col } from "react-bootstrap";
import logo from "../../assets/images/logo.png";
import SearchNavbar from "./SearchNavbar";
import { Explore } from "../words/OpenAiExploringWords";
import { generateClient } from "aws-amplify/api";
import { storedWordsByWord } from "../../graphql/queries";
import { createStoredWord } from "../../graphql/mutations";

function SiteNav(props) {
  const handleLogout = () => {
    props.logOut();
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);

  const getStoredWord = async (term) => {
    try {
      const client = generateClient();

      // Get a specific item
      const result = await client.graphql({
        query: storedWordsByWord,
        variables: { word: term.toLowerCase() },
      });

      if (result.data.storedWordsByWord.items.length > 0) {
        const wordData = result.data.storedWordsByWord.items[0];
        //wordData.jsonData = JSON.parse(wordData.jsonData);
        return JSON.parse(wordData.jsonData);
      } else {
        return null;
      }
    } catch (err) {
      console.error("Error fetching word by word:", err);
      return null;
    }
  };

  const storeWord = async (word, jsonData) => {
    try {
      const client = generateClient();

      const newWord = {
        word: word,
        version: 1,
        jsonData: JSON.stringify(jsonData),
      };
      // Get a specific item
      await client.graphql({
        query: createStoredWord,
        variables: { input: newWord },
      });
    } catch (err) {
      console.error("Error fetching word by word:", err);
      return null;
    }
  };

  const handleSearch = async (term) => {
    if (term.trim() === "") {
      return null;
    }

    setLoading(true);

    try {
      const storedWord = await getStoredWord(term);

      if (storedWord && !storeWord.found) {
        return storedWord;
      }

      const result = await Explore(term);

      if (result.found) {
        storeWord(term.toLowerCase(), result);
      }
      return result;
    } catch (error) {
      console.error("Error fetching search result:", error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <header>
        <Navbar bg="light" expand="lg" className="shadow-sm">
          <Container fluid>
            {/* First Row for Mobile */}
            <Row className="w-100 align-items-center d-flex d-lg-none">
              {/* Logo */}
              <Col xs="auto" className="d-flex align-items-center">
                <Navbar.Brand href="#">
                  <img
                    src={logo}
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                    alt="Logo"
                  />
                </Navbar.Brand>
              </Col>

              {/* Spacer */}
              <Col></Col>

              {/* Username and Logout Dropdown */}
              <Col xs="auto" className="d-flex justify-content-end px-0">
                <Nav className="align-items-center">
                  <Dropdown align="end">
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                      {props.username}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={handleLogout}>
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav>
              </Col>
            </Row>

            {/* Search Bar Row for Mobile */}
            <Row className="w-100 mx-0 mt-2 d-flex d-lg-none">
              <Col xs={12} className="px-0">
                <SearchNavbar
                  onSearch={handleSearch}
                  loading={loading}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  folders={props.folders}
                />
              </Col>
            </Row>

            {/* Combined Row for Desktop */}
            <Row className="w-100 align-items-center d-none d-lg-flex">
              {/* Logo */}
              <Col xs="auto" className="d-flex align-items-center">
                <Navbar.Brand href="#">
                  <img
                    src={logo}
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                    alt="Logo"
                  />
                </Navbar.Brand>
              </Col>

              {/* Empty Spacer Column */}
              <Col xs></Col>

              {/* Centered Search Bar */}
              <Col lg={6} className="d-flex justify-content-center">
                <SearchNavbar
                  onSearch={handleSearch}
                  loading={loading}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  folders={props.folders}
                />
              </Col>

              {/* Empty Spacer Column */}
              <Col xs></Col>

              {/* Username and Logout Dropdown */}
              <Col xs="auto" className="d-flex justify-content-end">
                <Nav className="align-items-center">
                  <Dropdown align="end">
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                      {props.username}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={handleLogout}>
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav>
              </Col>
            </Row>
          </Container>
        </Navbar>
      </header>
    </>
  );
}

export default SiteNav;
