// AppContent.js
import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { Spinner } from "react-bootstrap"; // Import Spinner
import SiteNav from "./common/SiteNav";
import HomePage from "../Screens/HomePage";
import FolderPage from "../Screens/FolderPage";
import { WordPage } from "../Screens/WordPage";
import { listFolders } from "../graphql/queries";
import { generateClient } from "aws-amplify/api";
import { fetchUserAttributes } from "aws-amplify/auth";
import { LearningSessionPage } from "../Screens/LearningSessionPage";

function AppContent({ signOut, user }) {
  const [folders, setFolders] = useState([]);
  const [loadingFolders, setLoadingFolders] = useState(true);
  const [fetchedUser, setFetchedUser] = useState(null);

  useEffect(() => {
    const fetchFolders = async () => {
      if (!user) {
        setFolders([]);
        setLoadingFolders(false);
        return;
      }

      try {
        const client = generateClient();
        const foldersData = await client.graphql({
          query: listFolders,
        });

        const folderList = foldersData.data.listFolders.items;
        setFolders(folderList);

        setFetchedUser(await fetchUserAttributes());
      } catch (error) {
        console.log("Error fetching folders:", error);
      } finally {
        setLoadingFolders(false);
      }
    };

    fetchFolders();
  }, [user]);

  if (loadingFolders) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  return (
    <div className="d-flex flex-column vh-100">
      {/* Navigation Bar */}
      <SiteNav
        logOut={signOut}
        username={fetchedUser.email}
        folders={folders}
      />

      {/* Main Content Area */}
      <div className="flex-grow-1 d-flex flex-column">
        <Routes>
          <Route path="/" element={<HomePage myfolders={folders} />} />
          <Route path="folders/:folderId" element={<FolderPage />} />
          <Route
            path="learningSession/:folderId"
            element={<LearningSessionPage />}
          />
          <Route path="folders/:folderId/words/:word" element={<WordPage />} />
          {/* Add other routes as needed */}
          <Route path="*" element={<HomePage />} />
        </Routes>
      </div>
    </div>
  );
}

export default AppContent;
