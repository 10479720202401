import React, { useState } from "react";
import {
  Card,
  Button,
  Form,
  Spinner,
  Badge,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import {
  FaForward,
  FaRedo,
  FaCheckCircle,
  FaLightbulb,
  FaBook,
  FaComments,
  FaRegSmile,
} from "react-icons/fa"; // Added FaRedo for the "One More Time" button

const FlashCard = ({ word, getSentence, checkTranslation, onNextCard }) => {
  const [loadingFeedback, setLoadingFeedback] = useState(false);
  const [loadingSentence, setLoadingSentence] = useState(false);
  const [nativeSentence, setNativeSentence] = useState("");
  const [userTranslation, setUserTranslation] = useState("");
  const [feedback, setFeedback] = useState(null);
  const [translationDisabled, setTranslationDisabled] = useState(false);

  // Fetch native sentence when the component mounts or when the card changes
  React.useEffect(() => {
    const fetchSentence = async () => {
      setLoadingSentence(true); // Show loader when getting new sentence
      const sentence = await getSentence(word);
      setNativeSentence(sentence);
      setLoadingSentence(false); // Hide loader when sentence is loaded
    };
    fetchSentence();
  }, [word, getSentence]);

  const handleSubmit = async () => {
    setTranslationDisabled(true); // Disable textarea immediately after clicking
    setLoadingFeedback(true); // Show loading spinner while feedback is fetched
    const response = await checkTranslation(
      word,
      nativeSentence,
      userTranslation,
    );
    setFeedback(response); // Store the full response to display
    setLoadingFeedback(false); // Hide loading spinner when feedback is received
  };

  const handleNextCard = () => {
    // Clear the input field, feedback, and re-enable the input for the next card
    setUserTranslation("");
    setFeedback(null);
    setTranslationDisabled(false);
    onNextCard(); // Call the onNextCard function to move to the next card
  };

  const handleRepeatCard = async () => {
    // Fetch another sentence for the same word
    setLoadingSentence(true);
    const sentence = await getSentence(word);
    setNativeSentence(sentence);
    setUserTranslation("");
    setFeedback(null);
    setTranslationDisabled(false);
    setLoadingSentence(false);
  };

  const handleSkipCard = () => {
    // Skip the current card and show the next one without feedback
    handleNextCard();
  };

  // Helper function to highlight differences between the user's translation and correct translation
  const highlightDifferences = (userText, correctText) => {
    if (!userText || !correctText) return correctText || ""; // Safeguard against undefined values
    const userWords = userText.split(" ");
    const correctWords = correctText.split(" ");

    return correctWords
      .map((word, index) =>
        userWords[index] === word
          ? word
          : `<span style="background-color: yellow">${word}</span>`,
      )
      .join(" ");
  };

  return (
    <Card
      className="mx-auto my-2"
      style={{ width: "100%", maxWidth: "600px", position: "relative" }}
    >
      <Card.Body>
        {/* Skip card button */}
        <Button
          variant="link"
          onClick={handleSkipCard}
          style={{
            position: "absolute",
            top: "5px",
            right: "15px",
            fontSize: "1.25rem",
            padding: "0",
          }}
        >
          <FaForward />
        </Button>

        {loadingSentence ? (
          <>
            <Spinner animation="border" size="sm" />{" "}
            <span>Getting sentence for you...</span>
          </>
        ) : (
          <>
            {/* Native sentence is always visible */}
            <Card.Title>Translate this sentence:</Card.Title>
            <Card.Text>{nativeSentence}</Card.Text>

            <Form>
              <Form.Group controlId="userTranslation">
                <Form.Label>Your Translation:</Form.Label>
                <div style={{ position: "relative" }}>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter your translation..."
                    value={userTranslation}
                    onChange={(e) => setUserTranslation(e.target.value)}
                    disabled={translationDisabled} // Disable textarea after user submits
                    style={{ resize: "none" }}
                  />
                  {feedback && feedback.english_level && (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>Your translation English level</Tooltip>
                      }
                    >
                      <Badge
                        style={{
                          position: "absolute",
                          bottom: "10px",
                          right: "10px",
                        }}
                      >
                        {feedback.english_level}
                      </Badge>
                    </OverlayTrigger>
                  )}
                </div>
              </Form.Group>

              {/* Loading spinner when fetching feedback */}
              {loadingFeedback && (
                <div className="text-center mt-3">
                  <Spinner animation="border" />
                  <p>Checking your translation...</p>
                </div>
              )}

              {/* Check Translation button (hidden after submission) */}
              {!translationDisabled && !loadingFeedback && (
                <Button
                  onClick={handleSubmit}
                  className="mt-3"
                  disabled={translationDisabled}
                >
                  {loadingFeedback ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Check Translation"
                  )}
                </Button>
              )}
            </Form>

            {feedback && (
              <>
                {/* Suggested Translation with highlighted differences */}
                <Card.Text>
                  <FaCheckCircle style={{ color: "green" }} />{" "}
                  <strong>Suggested Translation:</strong>{" "}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: highlightDifferences(
                        userTranslation,
                        feedback.correct_translation,
                      ),
                    }}
                  />
                  {feedback.correct_translation_english_level && (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>Suggested translation English level</Tooltip>
                      }
                    >
                      <Badge>
                        {feedback.correct_translation_english_level}
                      </Badge>
                    </OverlayTrigger>
                  )}
                </Card.Text>

                {/* Explanation */}
                <Card.Text>
                  <FaLightbulb style={{ color: "orange" }} />{" "}
                  <strong>Explanation:</strong>{" "}
                  {feedback.user_translation_feedback.explanation}
                </Card.Text>

                {/* Grammar Notes */}
                {feedback.user_translation_feedback.grammar_notes && (
                  <Card.Text>
                    <FaBook style={{ color: "purple" }} />{" "}
                    <strong>Grammar Notes:</strong>{" "}
                    {feedback.user_translation_feedback.grammar_notes}
                  </Card.Text>
                )}

                {/* Vocabulary Suggestions */}
                {feedback.user_translation_feedback.vocabulary_suggestions && (
                  <Card.Text>
                    <FaComments style={{ color: "blue" }} />{" "}
                    <strong>Vocabulary Suggestions:</strong>{" "}
                    {feedback.user_translation_feedback.vocabulary_suggestions.join(
                      ", ",
                    )}
                  </Card.Text>
                )}

                {/* Cultural Notes */}
                {feedback.user_translation_feedback.cultural_notes && (
                  <Card.Text>
                    <FaComments style={{ color: "brown" }} />{" "}
                    <strong>Cultural Notes:</strong>{" "}
                    {feedback.user_translation_feedback.cultural_notes}
                  </Card.Text>
                )}

                {/* Native Speaker Alternatives */}
                {feedback.native_speaker_alternatives && (
                  <Card.Text>
                    <FaLightbulb style={{ color: "orange" }} />{" "}
                    <strong>Native Speaker Alternatives:</strong>
                    <ul>
                      {feedback.native_speaker_alternatives.map(
                        (alternative, index) => (
                          <li key={index}>{alternative}</li>
                        ),
                      )}
                    </ul>
                  </Card.Text>
                )}

                {/* Positive Reinforcement */}
                {feedback.positive_reinforcement && (
                  <Card.Text>
                    <FaRegSmile style={{ color: "gold" }} />{" "}
                    {feedback.positive_reinforcement}
                  </Card.Text>
                )}

                <div className="d-flex justify-content-between mt-3">
                  {/* One More Time button */}
                  <Button
                    variant="outline-primary"
                    onClick={handleRepeatCard}
                    style={{ color: "blue", padding: "3" }}
                  >
                    <FaRedo className="pb-1" /> Repeat
                  </Button>

                  {/* Next Card button */}
                  <Button variant="outline-primary" onClick={handleNextCard}>
                    Next Card <FaForward className="pb-1" />
                  </Button>
                </div>
              </>
            )}
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default FlashCard;
