import { useParams } from "react-router-dom";
import WordOrPhraseDetails from "../components/words/WordOrPhraseDetails";
import { useEffect, useState } from "react";
import { generateClient } from "aws-amplify/api";
import { storedWordsByWord } from "../graphql/queries";
import { Button, Card, Col, Container, Row, Spinner } from "react-bootstrap";
import { Explore } from "../components/words/OpenAiExploringWords";
import { createStoredWord } from "../graphql/mutations";

export function WordPage() {
  let { folderId, word } = useParams();
  const [storedWordVariable, setStoredWordVarable] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log("folderId", folderId);
    setIsLoading(true);
    const getStoredWord = async () => {
      try {
        const client = generateClient();

        // Get a specific item
        const result = await client.graphql({
          query: storedWordsByWord,
          variables: { word: word.toLowerCase() },
        });

        if (result.data.storedWordsByWord.items.length > 0) {
          const wordData = result.data.storedWordsByWord.items[0];
          //wordData.jsonData = JSON.parse(wordData.jsonData);
          return JSON.parse(wordData.jsonData);
        } else {
          return null;
        }
      } catch (err) {
        console.error("Error fetching word by word:", err);
        return null;
      }
    };

    const storeWord = async (word, jsonData) => {
      try {
        const client = generateClient();

        const newWord = {
          word: word,
          version: 1,
          jsonData: JSON.stringify(jsonData),
        };
        // Get a specific item
        await client.graphql({
          query: createStoredWord,
          variables: { input: newWord },
        });
      } catch (err) {
        console.error("Error fetching word by word:", err);
        return null;
      }
    };

    const getThatWordFinally = async () => {
      try {
        const storedWord = await getStoredWord(word);

        if (storedWord && !storeWord.found) {
          setStoredWordVarable(storedWord);
          return;
        }

        const result = await Explore(word);

        if (result.found) {
          await storeWord(word.toLowerCase(), result);
        }

        setStoredWordVarable(result);
      } catch (error) {
        console.error("Error fetching search result:", error);
        setStoredWordVarable(null);
      } finally {
      }
    };

    getThatWordFinally();
  }, [word]);

  useEffect(() => {
    if (storedWordVariable) {
      setIsLoading(false);
    }
  }, [storedWordVariable]);

  return (
    <>
      <Container className="mt-5">
        <Row className="justify-content-center">
          <Col md="auto">
            <Button
              variant="link"
              href={"/folders/" + folderId}
              className="mt-0"
            >
              &larr; Back to Folder
            </Button>
            <Card style={{ maxWidth: "600px", width: "100%" }}>
              <Card.Body>
                {isLoading ? (
                  <div className="text-center p-3">
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : storedWordVariable && storedWordVariable.found ? (
                  <WordOrPhraseDetails
                    details={storedWordVariable}
                    folders={[]}
                    maxHeight={"700px"}
                  />
                ) : (
                  <div className="p-3">No results found for "{word}".</div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
