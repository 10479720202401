import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  FormControl,
  InputGroup,
  Button,
  Spinner,
} from "react-bootstrap";
import WordOrPhraseDetails from "../words/WordOrPhraseDetails";

function SearchNavbar({
  onSearch,
  loading,
  searchTerm,
  setSearchTerm,
  folders,
}) {
  const [showResults, setShowResults] = useState(false);
  const [searchResult, setSearchResult] = useState(null);
  const target = useRef(null);

  const handleOnChangeTerm = async (term) => {
    setShowResults(false);
    setSearchTerm(term);
  };

  const handleSearchClick = async () => {
    if (searchTerm.trim() === "") {
      setShowResults(false);
      return;
    }

    setShowResults(false);

    try {
      const result = await onSearch(searchTerm);

      setSearchResult(result);
      setShowResults(true);
    } catch (error) {
      console.error("Error fetching search result:", error);
      setSearchResult(null);
      setShowResults(true);
    }
  };

  // Close the search results when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (target.current && !target.current.contains(event.target)) {
        setShowResults(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [target]);

  return (
    <div ref={target} style={{ position: "relative", width: "100%" }}>
      <Form className="d-flex w-100" onSubmit={(e) => e.preventDefault()}>
        <InputGroup
          className={showResults ? "rounded-bottom-0" : ""}
          style={
            showResults
              ? { borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }
              : {}
          }
        >
          <FormControl
            placeholder="Search"
            aria-label="Search"
            value={searchTerm}
            onChange={(e) => handleOnChangeTerm(e.target.value)}
            style={
              showResults
                ? {
                    borderBottomRightRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderBottom: "none",
                  }
                : {}
            }
          />
          <Button
            variant="outline-secondary"
            onClick={handleSearchClick}
            disabled={loading}
            style={
              showResults
                ? { borderBottomRightRadius: 0, borderBottom: "none" }
                : {}
            }
          >
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Search"
            )}
          </Button>
        </InputGroup>
      </Form>

      {/* Overlay for Search Results */}
      {showResults && (
        <div
          className="position-absolute w-100 bg-white border"
          style={{
            zIndex: 1000,
            borderTop: "none",
            borderRadius: "0 0 4px 4px",
          }}
        >
          {loading ? (
            <div className="text-center p-3">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : searchResult && searchResult.found ? (
            <WordOrPhraseDetails
              details={searchResult}
              folders={folders}
              maxHeight={"300px"}
            />
          ) : (
            <div className="p-3">No results found for "{searchTerm}".</div>
          )}
        </div>
      )}
    </div>
  );
}

export default SearchNavbar;
