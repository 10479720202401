// WordOrPhraseDetails.js

import React, { useState } from "react";
import { Badge, Button, Form, Row, Col } from "react-bootstrap";
import {
  FaBookOpen,
  FaGlobe,
  FaTags,
  FaLightbulb,
  FaFolderPlus,
  FaVolumeUp,
  FaMicrophone,
} from "react-icons/fa";
import { createWord } from "../../graphql/mutations";
import { generateClient } from "@aws-amplify/api";
import { getSpeechAudio } from "../../utils/audioGetter"; // Adjust the path as needed

const WordOrPhraseDetails = ({ details, folders, maxHeight }) => {
  const {
    word,
    meaning,
    translation,
    pronunciation,
    usageFrequency,
    englishLevel,
    usageAreas,
    synonyms,
    antonyms,
    culturalNotes,
    examples,
    commonAssociations,
  } = details;

  // State variables
  const [selectedFolder, setSelectedFolder] = useState("");
  const [audioUrl, setAudioUrl] = useState(null);
  const [isLoadingAudio, setIsLoadingAudio] = useState(false);
  const [audioError, setAudioError] = useState(null);

  const handleAddToFolder = async () => {
    if (!selectedFolder) return;

    try {
      if (word) {
        const client = generateClient();

        await client.graphql({
          query: createWord,
          variables: {
            input: {
              text: word,
              folderID: selectedFolder,
            },
          },
        });
      }
    } catch (error) {
      console.error("Error adding word to folder:", error);
      // Optionally, set an error state to inform the user
    }
  };

  const handlePlayAudio = async () => {
    console.log("isLoadingAudio", isLoadingAudio);
    if (isLoadingAudio) return;

    setIsLoadingAudio(true);
    setAudioError(null);

    try {
      let url = audioUrl;

      console.log("audioUrl", audioUrl);

      if (!audioUrl) {
        // Fetch audio URL if not already fetched or expired
        url = await getSpeechAudio(word);

        console.log("url", url);

        setAudioUrl(url);
      }

      // Attempt to play the audio
      const audio = new Audio(url);
      audio.onended = () => {
        setIsLoadingAudio(false);
      };
      await audio.play();
    } catch (error) {
      console.error("Error fetching or playing audio:", error);
      setAudioError("Failed to fetch or play audio. Please try again.");
      setIsLoadingAudio(false);
    }
  };

  return (
    <div className="p-2">
      {/* Header */}
      <div className="d-flex justify-content-between align-items-center">
        <h5 className="mb-0 d-flex align-items-center">
          {word}
          <FaVolumeUp
            className="ms-2 text-primary"
            style={{ cursor: "pointer" }}
            onClick={handlePlayAudio}
            aria-label="Play Audio"
          />
          <Badge
            bg="info"
            className="ms-2 align-text-top"
            style={{ fontSize: "0.75rem", padding: "0.25em 0.4em" }}
          >
            {englishLevel}
          </Badge>
          <Badge
            bg="secondary"
            className="align-text-top ms-1"
            style={{ fontSize: "0.75rem", padding: "0.25em 0.4em" }}
          >
            {usageFrequency}
          </Badge>
        </h5>
      </div>

      {/* Loading and Error Messages */}
      {isLoadingAudio && (
        <div className="mt-2">
          <span>Loading audio...</span>
        </div>
      )}
      {audioError && (
        <div className="mt-2 text-danger">
          <span>{audioError}</span>
        </div>
      )}

      {/* Content */}
      <div className="mt-2" style={{ maxHeight: maxHeight, overflowY: "auto" }}>
        {/* Meaning */}
        <p className="mb-1">
          <FaBookOpen className="me-2 text-primary" />
          <strong>Meaning:</strong> {meaning}
        </p>

        {/* Translation */}
        {translation && (
          <p className="mb-1">
            <FaGlobe className="me-2 text-success" />
            <strong>Translation:</strong> <em>{translation}</em>
          </p>
        )}

        {/* Pronunciation */}
        {pronunciation && (
          <p className="mb-1">
            <FaMicrophone className="me-2 text-danger" />
            <strong>Pronunciation:</strong> {pronunciation}
          </p>
        )}

        {/* Usage Areas */}
        {usageAreas?.length > 0 && (
          <p className="mb-1">
            <FaTags className="me-2 text-warning" />
            <strong>Usage Areas:</strong> {usageAreas.join(", ")}
          </p>
        )}

        {/* Synonyms and Antonyms */}
        {(synonyms?.length > 0 || antonyms?.length > 0) && (
          <div>
            {synonyms?.length > 0 && (
              <p className="mb-1">
                <FaLightbulb className="me-2 text-info" />
                <strong>Synonyms:</strong> {synonyms.join(", ")}
              </p>
            )}
            {antonyms?.length > 0 && (
              <p className="mb-1">
                <FaLightbulb className="me-2 text-secondary" />
                <strong>Antonyms:</strong> {antonyms.join(", ")}
              </p>
            )}
          </div>
        )}

        {/* Examples */}
        {examples?.length > 0 && (
          <div>
            <p className="mb-1">
              <FaBookOpen className="me-2 text-primary" />
              <strong>Examples:</strong>
            </p>
            {examples.map((example, index) => (
              <p key={index} className="ms-4 mb-1">
                {example.english} <em className="ms-1">{example.russian}</em>
              </p>
            ))}
          </div>
        )}

        {/* Common Associations */}
        {commonAssociations?.length > 0 && (
          <div>
            <p className="mb-1">
              <FaLightbulb className="me-2 text-warning" />
              <strong>Common Associations:</strong>
            </p>
            {commonAssociations.map((association, index) => (
              <p key={index} className="ms-4 mb-1">
                <strong>{association.collocation}:</strong>{" "}
                {association.englishExample}{" "}
                <em className="ms-1">{association.russianTranslation}</em>
              </p>
            ))}
          </div>
        )}

        {/* Cultural Notes */}
        {culturalNotes && (
          <p className="mb-1">
            <FaLightbulb className="me-2 text-muted" />
            <strong>Cultural Notes:</strong> {culturalNotes}
          </p>
        )}
      </div>

      {folders.length > 0 && (
        <Row className="mt-3">
          <Col xs={12} sm={8}>
            <Form.Select
              value={selectedFolder}
              onChange={(e) => setSelectedFolder(e.target.value)}
            >
              <option value="">Select folder</option>
              {folders.map((folder) => (
                <option key={folder.id} value={folder.id}>
                  {folder.title}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col xs={12} sm={4} className="mt-2 mt-sm-0">
            <Button
              variant="primary"
              disabled={!selectedFolder}
              className="w-100"
              onClick={handleAddToFolder}
            >
              <FaFolderPlus className="me-2" />
              Add to Folder
            </Button>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default WordOrPhraseDetails;
