import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Button, ListGroup, Container, Row, Col } from "react-bootstrap";
import { FaTimes, FaFileAlt } from "react-icons/fa";

import { generateClient } from "aws-amplify/api";
import { deleteWord } from "../graphql/mutations";
import { getFolder, wordsByFolderID } from "../graphql/queries";

function FolderPage() {
  let { folderId } = useParams();
  let [folder, setFolder] = useState({});
  const [words, setWords] = useState([]);
  const navigate = useNavigate();

  const goToWordPage = (wordText) => {
    navigate(`words/${wordText}`);
  };

  useEffect(() => {
    const getFolderModel = async () => {
      try {
        const client = generateClient();

        // Get a specific item
        const folderData = await client.graphql({
          query: getFolder,
          variables: { id: folderId },
        });

        setFolder(folderData.data.getFolder);

        const wordsData = await client.graphql({
          query: wordsByFolderID,
          variables: {
            folderID: folderId,
          },
        });

        setWords(wordsData.data.wordsByFolderID.items);
      } catch (err) {
        console.log("error", err);
      }
    };

    getFolderModel();
  }, [folderId]); // Add folderId as a dependency

  const deleteItem = async (elementId) => {
    const client = generateClient();

    await client.graphql({
      query: deleteWord,
      variables: {
        input: {
          id: elementId,
        },
      },
    });

    setWords(words.filter((obj) => obj.id !== elementId));
  };

  return (
    <Container className="mt-4">
      <h3>{folder.title}</h3>
      <Button variant="link" href="/" className="mt-2">
        &larr; Back
      </Button>

      <div className="mx-auto w-100 w-md-50 w-lg-25">
        <ListGroup>
          {words.map((element, index) => (
            <ListGroup.Item key={element.id}>
              <Row className="align-items-center">
                {/* Text with some space between checkbox */}
                <Col className="text-left">{element.text}</Col>

                <Col xs="auto">
                  <FaFileAlt
                    onClick={() => goToWordPage(element.text)}
                    style={{ cursor: "pointer", color: "green" }}
                  />
                </Col>

                {/* Red cross on the far left */}
                <Col xs="auto">
                  <FaTimes
                    onClick={async () => await deleteItem(element.id)}
                    style={{ cursor: "pointer", color: "red" }}
                  />
                </Col>
              </Row>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </div>
    </Container>
  );
}

export default FolderPage;
