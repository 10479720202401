/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getWord = /* GraphQL */ `
  query GetWord($id: ID!) {
    getWord(id: $id) {
      id
      text
      folderID
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listWords = /* GraphQL */ `
  query ListWords(
    $filter: ModelWordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWords(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        text
        folderID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const wordsByFolderID = /* GraphQL */ `
  query WordsByFolderID(
    $folderID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelWordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    wordsByFolderID(
      folderID: $folderID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        text
        folderID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFolder = /* GraphQL */ `
  query GetFolder($id: ID!) {
    getFolder(id: $id) {
      id
      title
      Words {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listFolders = /* GraphQL */ `
  query ListFolders(
    $filter: ModelFolderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFolders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStoredWord = /* GraphQL */ `
  query GetStoredWord($id: ID!) {
    getStoredWord(id: $id) {
      id
      word
      version
      jsonData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listStoredWords = /* GraphQL */ `
  query ListStoredWords(
    $filter: ModelStoredWordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStoredWords(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        word
        version
        jsonData
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const storedWordsByWord = /* GraphQL */ `
  query StoredWordsByWord(
    $word: String!
    $sortDirection: ModelSortDirection
    $filter: ModelStoredWordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    storedWordsByWord(
      word: $word
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        word
        version
        jsonData
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
