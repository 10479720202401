/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createWord = /* GraphQL */ `
  mutation CreateWord(
    $input: CreateWordInput!
    $condition: ModelWordConditionInput
  ) {
    createWord(input: $input, condition: $condition) {
      id
      text
      folderID
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateWord = /* GraphQL */ `
  mutation UpdateWord(
    $input: UpdateWordInput!
    $condition: ModelWordConditionInput
  ) {
    updateWord(input: $input, condition: $condition) {
      id
      text
      folderID
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteWord = /* GraphQL */ `
  mutation DeleteWord(
    $input: DeleteWordInput!
    $condition: ModelWordConditionInput
  ) {
    deleteWord(input: $input, condition: $condition) {
      id
      text
      folderID
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createFolder = /* GraphQL */ `
  mutation CreateFolder(
    $input: CreateFolderInput!
    $condition: ModelFolderConditionInput
  ) {
    createFolder(input: $input, condition: $condition) {
      id
      title
      Words {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateFolder = /* GraphQL */ `
  mutation UpdateFolder(
    $input: UpdateFolderInput!
    $condition: ModelFolderConditionInput
  ) {
    updateFolder(input: $input, condition: $condition) {
      id
      title
      Words {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteFolder = /* GraphQL */ `
  mutation DeleteFolder(
    $input: DeleteFolderInput!
    $condition: ModelFolderConditionInput
  ) {
    deleteFolder(input: $input, condition: $condition) {
      id
      title
      Words {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createStoredWord = /* GraphQL */ `
  mutation CreateStoredWord(
    $input: CreateStoredWordInput!
    $condition: ModelStoredWordConditionInput
  ) {
    createStoredWord(input: $input, condition: $condition) {
      id
      word
      version
      jsonData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateStoredWord = /* GraphQL */ `
  mutation UpdateStoredWord(
    $input: UpdateStoredWordInput!
    $condition: ModelStoredWordConditionInput
  ) {
    updateStoredWord(input: $input, condition: $condition) {
      id
      word
      version
      jsonData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteStoredWord = /* GraphQL */ `
  mutation DeleteStoredWord(
    $input: DeleteStoredWordInput!
    $condition: ModelStoredWordConditionInput
  ) {
    deleteStoredWord(input: $input, condition: $condition) {
      id
      word
      version
      jsonData
      createdAt
      updatedAt
      __typename
    }
  }
`;
