import { SendToOpenAiWithFunction } from "../../utils/openaiSender";

export async function GetTranslationFeedback(word, russianSentence, translate) {
  const get_translation_feedback = {
    name: "get_translation_feedback",
    description: "Call this if the user ask translation feedback",
    strict: true,
    parameters: {
      type: "object",
      properties: {
        correct_translation: {
          type: "string",
          description:
            "The correct translation of the sentence provided by the user.",
        },
        english_level: {
          type: "string",
          description:
            "The English proficiency level of the user's translation based on CEFR.",
        },
        correct_translation_english_level: {
          type: "string",
          description:
            "The English proficiency level of the correct translation based on CEFR.",
        },
        user_translation_feedback: {
          type: "object",
          properties: {
            explanation: {
              type: "string",
              description:
                "Explanation of why the translation is correct or incorrect, including grammar and vocabulary details.",
            },
            grammar_notes: {
              type: "string",
              description:
                "Specific grammatical points related to the sentence.",
            },
            vocabulary_suggestions: {
              type: "array",
              items: {
                type: "string",
              },
              description:
                "Alternative vocabulary suggestions to improve the translation.",
            },
            cultural_notes: {
              type: "string",
              description:
                "Additional cultural context or nuances that apply to the translation.",
            },
          },
          required: [
            "explanation",
            "grammar_notes",
            "vocabulary_suggestions",
            "cultural_notes",
          ],
          additionalProperties: false,
        },

        native_speaker_alternatives: {
          type: "array",
          items: {
            type: "string",
          },
          description:
            "Two or three alternative ways a native speaker might say the same sentence.",
        },
        positive_reinforcement: {
          type: "string",
          description: "Positive feedback to motivate the user.",
        },
      },
      required: [
        "correct_translation",
        "english_level",
        "correct_translation_english_level",
        "user_translation_feedback",
        "native_speaker_alternatives",
        "positive_reinforcement",
      ],
      additionalProperties: false,
    },
  };

  const messages = [
    {
      role: "system",
      content: `You are a cheerful and supportive assistant helping non-native English speakers improve their English language skills. Your goal is to provide constructive, encouraging feedback on their translations while maintaining a positive and motivational tone.

You will receive a JSON object with three fields:

word: A word the user must include in their translation.
russianSentence: A sentence in Russian that the user will translate into English.
translate: The user's translation of the sentence.

Provide to user translation feedback.
Check if the user used the provided word.

Do not include any additional text or formatting in your response.`,
    },
    {
      role: "user",
      content: `{word: "${word}", russianSentence: "${russianSentence}", translate: "${translate}"}`,
    },
  ];

  const tools = [
    {
      type: "function",
      function: get_translation_feedback,
    },
  ];

  const reply = await SendToOpenAiWithFunction(messages, tools);

  // Parse the JSON string into an object
  const assistantMessage = JSON.parse(
    reply.choices[0].message.tool_calls[0].function.arguments,
  );

  return assistantMessage;
}
