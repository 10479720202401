import { SendToOpenAiWithFunction } from "../../utils/openaiSender";

export async function Explore(word) {
  const get_word_information = {
    name: "get_word_information",
    description: "Call this if the user sends a word or phrase",
    strict: true,
    parameters: {
      type: "object",
      properties: {
        found: {
          type: "boolean",
          description: "Indicates whether the word or phrase was found.",
        },
        word: {
          type: "string",
          description: "The original English word or phrase.",
        },
        meaning: {
          type: "string",
          description: "A clear and concise definition in English.",
        },
        translation: {
          type: "string",
          description: "The Russian translation of the word.",
        },
        pronunciation: {
          type: "string",
          description: "The phonetic transcription using IPA.",
        },
        usageFrequency: {
          type: "string",
          description:
            "How commonly the word is used (e.g., common, uncommon).",
        },
        englishLevel: {
          type: "string",
          description: "The CEFR level of the word (A1, A2, B1, B2, C1, C2).",
        },
        usageAreas: {
          type: "array",
          description: "An array of areas or contexts where the word is used.",
          items: {
            type: "string",
          },
        },
        synonyms: {
          type: "array",
          description: "An array of synonyms of the word.",
          items: {
            type: "string",
          },
        },
        antonyms: {
          type: "array",
          description: "An array of antonyms of the word.",
          items: {
            type: "string",
          },
        },
        culturalNotes: {
          type: "string",
          description: "Cultural context or significance related to the word.",
        },
        examples: {
          type: "array",
          description: "An array of example sentences.",
          items: {
            type: "object",
            properties: {
              english: {
                type: "string",
                description: "An example sentence using the word.",
              },
              russian: {
                type: "string",
                description: "The Russian translation of the sentence.",
              },
            },
            required: ["english", "russian"],
            additionalProperties: false,
          },
        },
        commonAssociations: {
          type: "array",
          description: "An array of commonly associated words or phrases.",
          items: {
            type: "object",
            properties: {
              collocation: {
                type: "string",
                description: "A commonly associated word or preposition.",
              },
              englishExample: {
                type: "string",
                description: "An English sentence using the collocation.",
              },
              russianTranslation: {
                type: "string",
                description: "The Russian translation of the sentence.",
              },
            },
            required: ["collocation", "englishExample", "russianTranslation"],
            additionalProperties: false,
          },
        },
      },
      required: [
        "found",
        "word",
        "meaning",
        "translation",
        "pronunciation",
        "usageFrequency",
        "englishLevel",
        "usageAreas",
        "synonyms",
        "antonyms",
        "culturalNotes",
        "examples",
        "commonAssociations",
      ],
      additionalProperties: false,
    },
  };

  const messages = [
    {
      role: "system",
      content: `You are an assistant that provides detailed information about English words for a language learning application designed for Russian speakers.

If the word is not found or is invalid, return a JSON object with the "word" field and an "error" field explaining that the word was not found.

Do not include any additional text or formatting in your response.`,
    },
    {
      role: "user",
      content: word,
    },
  ];

  const tools = [
    {
      type: "function",
      function: get_word_information,
    },
  ];

  const reply = await SendToOpenAiWithFunction(messages, tools);

  // Parse the JSON string into an object
  const assistantMessage = JSON.parse(
    reply.choices[0].message.tool_calls[0].function.arguments,
  );

  return assistantMessage;
}
