import { generateClient } from "aws-amplify/api";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getFolder, wordsByFolderID } from "../graphql/queries";
import Deck from "../components/flashcards/Deck";
import { Container } from "react-bootstrap"; // Import Bootstrap Container

export function LearningSessionPage() {
  let { folderId } = useParams();
  let [folder, setFolder] = useState({});
  const [words, setWords] = useState([]);

  useEffect(() => {
    const getFolderModel = async () => {
      try {
        const client = generateClient();

        // Get a specific item
        const folderData = await client.graphql({
          query: getFolder,
          variables: { id: folderId },
        });

        setFolder(folderData.data.getFolder);

        const wordsData = await client.graphql({
          query: wordsByFolderID,
          variables: {
            folderID: folderId,
          },
        });

        setWords(wordsData.data.wordsByFolderID.items);
      } catch (err) {
        console.log("error", err);
      }
    };

    getFolderModel();
  }, [folderId]);

  return (
    <Container className="d-flex justify-content-center my-4">
      <div className="card p-4 shadow-sm w-100" style={{ maxWidth: "650px" }}>
        <Deck words={words} folder={folder} />
      </div>
    </Container>
  );
}
