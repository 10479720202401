import { SendToOpenAi } from "../../utils/openaiSender";

export async function GetNativeSentence(word) {
  const getNativeSentence = [
    {
      role: "system",
      content:
        "You are an English learning assistant for non-native speakers with Russian as their native language. Your task is to help users learn English vocabulary through translation exercises. When the user provides you with an English word, respond with a well-structured sentence in Russian that incorporates the Russian equivalent of the given word. Ensure the sentence contains no English words. If the word is at a B2 level or higher, adjust the complexity of the sentence to match that proficiency level.",
    },
    {
      role: "user",
      content: `'${word}'`,
    },
  ];

  const reply = await SendToOpenAi(getNativeSentence);

  return [...getNativeSentence, reply.message];
}

export async function GetNativeSentenceForFlashCard(word) {
  const getNativeSentence = [
    {
      role: "system",
      content:
        "You are an English learning assistant for non-native speakers with Russian as their native language. Your task is to help users learn English vocabulary through translation exercises. When the user provides you with an English word, respond with a well-structured sentence in Russian that incorporates the Russian equivalent of the given word. Ensure the sentence contains no English words. If the word is at a B2 level or higher, adjust the complexity of the sentence to match that proficiency level.",
    },
    {
      role: "user",
      content: `'${word}'`,
    },
  ];

  const reply = await SendToOpenAi(getNativeSentence);

  return reply.message.content;
}

export async function GetTranslationFeedback(
  word,
  nativeSentence,
  translation,
) {
  const systemPrompt = `You are an AI language tutor assisting a Russian-speaking user in learning English. The user will provide you with a JSON object containing:

- **word**: An English word that should be used in the translation.
- **nativeSentence**: A sentence in Russian.
- **translation**: The user's translation of the native sentence into English, incorporating the given word.

Your tasks are:

1. **Provide the Correct Translation:**
   - Offer a corrected version of the translation that accurately reflects the original meaning and uses the given word appropriately.

2. **Explain Mistakes:**
   - Identify any grammatical, lexical, or stylistic errors in the user's translation.
   - Point out specific errors and provide clear and concise explanations to help the user understand and learn from these mistakes.

3. **Native English Speaker Might Say:**
   - Propose how a native English speaker might express the sentence.
   - Provide options using the given word and also using other suitable words or expressions.

Ensure that your responses are clear, educational, and supportive to enhance the user's learning experience.

**Example Input:**

\`\`\`json
{
  "word": "prompt",
  "nativeSentence": "Учитель дал нам подсказку.",
  "translation": "A teacher gives a promp to us."
}
\`\`\`

**Example Output:**

1. **Correct Translation:**

   - "The teacher gave us a prompt."

2. **Explanation of Mistakes:**

   - **Spelling Error:** "Promp" should be spelled "prompt."
   - **Verb Tense:** The original sentence is in the past tense; use "gave" instead of "gives."
   - **Article Usage:** Use "The" when referring to a specific teacher known in the context.
   - **Word Order:** In English, it's more natural to say "gave us a prompt" rather than "gave a prompt to us."

3. **Native English Speaker Might Say:**

   - **Using the given word:**
     - "The teacher provided us with a prompt."
   - **Using a different word:**
     - "The teacher gave us a hint."
     - "Our teacher offered us a clue."
`;

  const userInput = `{
    "word": "${word}",
    "nativeSentence": "${nativeSentence}",
    "translation": "${translation}"
  }`;

  const messages = [
    { role: "system", content: systemPrompt },
    { role: "user", content: userInput },
  ];

  const reply = await SendToOpenAi(messages);

  return [...messages, reply.message];
}

export async function GetTranslationFeedbackForFlashCard(
  word,
  nativeSentence,
  translation,
) {
  const systemPrompt = `You are an AI language tutor assisting a Russian-speaking user in learning English. The user will provide you with a JSON object containing:

- **word**: An English word that should be used in the translation.
- **nativeSentence**: A sentence in Russian.
- **translation**: The user's translation of the native sentence into English, incorporating the given word.

Your tasks are:

1. **Provide the Correct Translation:**
   - Offer a corrected version of the translation that accurately reflects the original meaning and uses the given word appropriately.

2. **Explain Mistakes:**
   - Identify any grammatical, lexical, or stylistic errors in the user's translation.
   - Point out specific errors and provide clear and concise explanations to help the user understand and learn from these mistakes.

3. **Native English Speaker Might Say:**
   - Propose how a native English speaker might express the sentence.
   - Provide options using the given word and also using other suitable words or expressions.

Ensure that your responses are clear, educational, and supportive to enhance the user's learning experience.

**Example Input:**

\`\`\`json
{
  "word": "prompt",
  "nativeSentence": "Учитель дал нам подсказку.",
  "translation": "A teacher gives a promp to us."
}
\`\`\`

**Example Output:**

1. **Correct Translation:**

   - "The teacher gave us a prompt."

2. **Explanation of Mistakes:**

   - **Spelling Error:** "Promp" should be spelled "prompt."
   - **Verb Tense:** The original sentence is in the past tense; use "gave" instead of "gives."
   - **Article Usage:** Use "The" when referring to a specific teacher known in the context.
   - **Word Order:** In English, it's more natural to say "gave us a prompt" rather than "gave a prompt to us."

3. **Native English Speaker Might Say:**

   - **Using the given word:**
     - "The teacher provided us with a prompt."
   - **Using a different word:**
     - "The teacher gave us a hint."
     - "Our teacher offered us a clue."
`;

  const userInput = `{
    "word": "${word}",
    "nativeSentence": "${nativeSentence}",
    "translation": "${translation}"
  }`;

  const messages = [
    { role: "system", content: systemPrompt },
    { role: "user", content: userInput },
  ];

  const reply = await SendToOpenAi(messages);

  return reply.message.content;
}
