import { post } from "@aws-amplify/api";

export async function getSpeechAudio(text) {
  try {
    // Make a POST request to your API to get the audio URL
    const apiCall = post({
      apiName: "textToSpeechAPI",
      path: "/synthesize",
      options: {
        body: { text },
        headers: {
          "Content-Type": "application/json",
        },
      },
    });

    const { body } = await apiCall.response;

    const bodyJson = await body.json();

    // Return the audio URL
    return bodyJson.audioUrl;
  } catch (error) {
    console.error("Error fetching audio:", error);
    throw error;
  }
}
