import { post } from "aws-amplify/api";

export async function SendToOpenAi(messages) {
  const openAiCall = post({
    apiName: "openaiApi",
    path: "/openai",
    options: {
      body: {
        messages: messages,
      },
    },
  });

  const { body } = await openAiCall.response;

  const bodyJson = await body.json(); // { role: 'assistant', content: '...' }

  return bodyJson;
}

export async function SendToOpenAiWithFunction(messages, tools) {
  const openAiCall = post({
    apiName: "openaiApi",
    path: "/exploreword",
    options: {
      body: {
        messages: messages,
        tools: tools,
      },
    },
  });

  const { body } = await openAiCall.response;

  const bodyJson = await body.json(); // { role: 'assistant', content: '...' }

  return bodyJson;
}
